import React from 'react';
import styled from 'styled-components';

import { Section, Container, Card } from 'components/global';
import ExternalLink from 'common/ExternalLink';

import theme from '../../theme';

import AaveLogo from 'static/logos/aave.svg';
import L4Logo from 'static/logos/L4.svg';
import EthereumLogo from 'static/logos/Ethereum.svg';
import ProtocolLogo from 'static/logos/protocollabs.svg';
import ZeroXLogo from 'static/logos/0x.svg';
import ConsensysLogo from 'static/logos/consensys.svg';
import ENSLogo from 'static/logos/ens.svg';
import NucypherLogo from 'static/logos/nucypher.svg';
import SkaleLogo from 'static/logos/skale.svg';

const PARTNERS = [
  {
    name: 'L4',
    logo: L4Logo,
    href: 'http://l4.ventures/',
  },
  {
    name: 'Ethereum Foudation',
    logo: EthereumLogo,
    href: 'https://ethereum.org/',
  },
  {
    name: 'Protocol Labs',
    logo: ProtocolLogo,
    href: 'https://protocol.ai/',
  },
  {
    name: 'Aave',
    logo: AaveLogo,
    href: 'https://aave.com/',
  },
  {
    name: '0x',
    logo: ZeroXLogo,
    href: 'https://0x.corg/',
  },
  {
    name: 'ConsenSys',
    logo: ConsensysLogo,
    href: 'https://consensys.net/',
  },
  {
    name: 'ENS',
    logo: ENSLogo,
    href: 'https://ens.domains/',
  },
  {
    name: 'NuCypher',
    logo: NucypherLogo,
    href: 'https://nucypher.com/',
  },
  {
    name: 'SKALE Labs',
    logo: SkaleLogo,
    href: 'https://skale.network/',
  },
];

const Partners = props => null;

const Grid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  justify-items: center;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ImgCard = styled(Card)`
  height: 100%;
  max-height: 220px;
  max-width: 360px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 250px;
  }

  @media (max-width: 991px) {
    padding: 24px;
    min-height: 120px;
  }
`;

export default Partners;
