import React from 'react';
import styled from 'styled-components';

import { Card, Input, Button } from 'components/global';

import MailBoxArt from 'static/Mailbox.svg';


const NewsLetterForm = props => (
  <Card {...props}>
    <Centered>
      <img src={MailBoxArt} alt="Mailbox" />
      <h3>Get in sync</h3>
      <p>
        Sign up for our mailing list to get updated about ETHGlobal
        events.
      </p>
      <a href="https://airtable.com/shrZ89OEwQJRtXHPv">
        <Button type="submit" name="subscribe" id="mc-embedded-subscribe">
          Subscribe
        </Button>
      </a>
    </Centered>

  </Card>
);

const Centered = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;

  img {
    margin-bottom: 24px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const Form = styled.form`
  display: flex;

  ${Input} {
    flex: 2;
    margin-right: 16px;
  }

  ${Button} {
    flex: 1;
  }

  > * {
    margin-top: 16px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export default NewsLetterForm;
