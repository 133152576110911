import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { Section, Container } from 'components/global';
import EventCard from 'common/EventCard';
import HorizontalScroller from 'common/HorizontalScroller';

import theme from '../../theme';
import { StaticQuery, graphql } from 'gatsby';

const UPCOMING_EVENTS = [
  
  {
    title: 'HackMoney 2022',
    location: 'Worldwide',
    dates: 'May 6 - 27, 2022',
    avatar: 'hm2.png',
    primaryColor: '#000000',
    href: 'https://defi.ethglobal.com/',
  },
  {
    title: 'ETHNewYork',
    location: 'Manhattan, New York',
    dates: 'Jun 24-26, 2022',
    avatar: 'ethnewyork.png',
    primaryColor: '#000000',
    href: 'https://nyc.ethglobal.com',
  },
];

const PREVIOUS_EVENTS = [
  {
    title: 'ETHAmsterdam',
    location: 'Amsterdam',
    dates: 'Apr 22 - 24, 2022',
    avatar: 'ethamsterdam.png',
    primaryColor: '#FF9B00',
    href: 'https://amsterdam.ethglobal.com/',
  },
  {
    title: 'DAOHacks',
    location: 'Worldwide',
    dates: 'Apr 8 - 10, 2022',
    avatar: 'daohacks.png',
    primaryColor: '#7856FB',
    href: 'https://dao.ethglobal.com/',
  },
  {
    title: 'LFGrow',
    location: 'Worldwide',
    dates: 'Mar 18 - 30, 2022',
    avatar: 'lfgrow.png',
    primaryColor: '#00501E',
    href: 'https://lfgrow.ethglobal.com/',
  },
  {
    title: 'BuildQuest',
    location: 'Worldwide',
    dates: 'Mar 4 - 16, 2022',
    avatar: 'buildquest.png',
    primaryColor: '#CF38CD',
    href: 'https://buildquest.ethglobal.com/',
  },
  {
    title: 'Road to Web3',
    location: 'Worldwide',
    dates: 'Feb 3 - 9, 2022',
    avatar: 'roadtoweb3.png',
    primaryColor: '#673ACF',
    href: 'https://web3.ethglobal.com/',
  },
  {
    title: 'NFTHack 2022',
    location: 'Worldwide',
    dates: 'Jan 14 - 16, 2022',
    avatar: 'nfthack.png',
    primaryColor: '#FF8585',
    href: 'https://nft.ethglobal.com/',
  },
  {
    title: 'Web3 Jam',
    location: 'Worldwide',
    dates: 'Nov 12 - Nov 24, 2021',
    avatar: 'web3jam.png',
    primaryColor: '#8362C5',
    href: 'https://jam.ethglobal.com/',
  },
  {
    title: 'UniCode',
    location: 'Worldwide',
    dates: 'Oct 29 - Nov 10, 2021',
    avatar: 'unicode.png',
    primaryColor: '#2262FB',
    href: 'https://unicode.ethglobal.com/',
  },
  {
    title: 'ETHOnline',
    location: 'Worldwide',
    dates: 'Sept 17 - Oct 15, 2021',
    avatar: 'ethonline.png',
    primaryColor: '#F1FFFF',
    href: 'https://online.ethglobal.com/',
  },
  {
    title: 'HackFS',
    location: 'Worldwide',
    dates: 'July, 2021',
    avatar: 'hfs2.png',
    primaryColor: '#24061E',
    href: 'https://fs.ethglobal.com/',
  },
  {
    title: 'HackMoney',
    location: 'Worldwide',
    dates: 'June, 2021',
    avatar: 'hm2.png',
    primaryColor: '#000000',
    href: 'https://defi.ethglobal.com/',
  },
  {
    title: 'Web3 Weekend',
    location: 'Worldwide',
    dates: 'May 28 - May 30',
    avatar: 'w3weekend.png',
    primaryColor: '#2D36C6',
    href: 'https://web3weekend.ethglobal.com/',
  },
  {
    title: 'Scaling Ethereum',
    location: 'Worldwide',
    dates: 'April, 2021',
    avatar: 'scaling.png',
    primaryColor: '#000000',
    href: 'https://scaling.ethglobal.com/',
  },
  {
    title: 'NFTHack',
    location: 'Worldwide',
    dates: 'March 19, 2021',
    avatar: 'nfthack.png',
    primaryColor: '#FF8585',
    href: 'https://nft.ethglobal.com/2021',
  },
  {
    title: 'MarketMake',
    location: 'Worldwide',
    dates: 'January, 2021',
    avatar: 'marketmake.png',
    primaryColor: '#262B44',
    href: 'https://mm.ethglobal.com/',
  },
  {
    title: 'ETHOnline',
    location: 'Worldwide',
    dates: 'October, 2020',
    avatar: 'ethonline.png',
    primaryColor: '#fffded',
    href: 'https://online.ethglobal.com/2020',
  },
  {
    title: 'HackFS',
    location: 'Worldwide',
    dates: 'July, 2020',
    avatar: 'hackfs.png',
    primaryColor: '#DBEBF9',
    href: 'https://hackfs.com',
  },
  {
    title: 'HackMoney',
    location: 'Worldwide',
    dates: 'April, 2020',
    avatar: 'hackmoney.png',
    primaryColor: '#262B44',
    href: 'https://hackathon.money',
  },
  {
    title: 'ETHLondonUK',
    location: 'London, United Kingdom',
    dates: 'Feb 28 - 1, 2020',
    avatar: 'ethlondon.jpg',
    primaryColor: '#4c3420',
    href: 'https://ethlondon.com',
  },
  {
    title: 'ETHWaterloo II',
    location: 'Waterloo, Ontario',
    dates: 'Nov 8-10, 2019',
    avatar: 'ethwaterloo-2.png',
    primaryColor: '#4c3420',
    href: 'https://ethwaterloo.com/',
  },
  {
    title: 'ETHBoston',
    location: 'Boston, Massachusetts',
    dates: 'Sep 6-8, 2019',
    avatar: 'ethboston.png',
    primaryColor: '#d0f0f8',
    href: 'https://eth.boston',
  },
  {
    title: 'ETHIndia II',
    location: 'Bangalore, India',
    dates: 'August 2-4, 2019',
    avatar: 'ethindia.jpg',
    primaryColor: '#5072ce',
    href: 'https://ethindia.co/',
  },
  {
    title: 'ETHNewYork',
    location: 'Brooklyn, New York',
    dates: 'May 17-19, 2019',
    avatar: 'ethnewyork.png',
    primaryColor: '#000000',
    href: 'http://ethnewyork.com',
  },
  {
    title: 'ETHCapeTown',
    location: 'Cape Town, South Africa',
    dates: 'Apr 19-20, 2019',
    avatar: 'ethcapetown.jpg',
    primaryColor: '#2F302E',
    href: 'https://ethcapetown.com/',
  },
  {
    title: 'ETHParis',
    location: 'Paris, France',
    dates: 'Mar 8-10, 2019',
    avatar: 'ethparis.jpg',
    primaryColor: '#8C09FF',
    href: 'https://ethparis.com/',
  },
  {
    title: 'ETHDenver II',
    location: 'Denver, Colorado',
    dates: 'Feb 15-17, 2019',
    avatar: 'ethdenver.jpg',
    primaryColor: '#FBD0DA',
    href: 'https://ethdenver.com/',
  },
  {
    title: 'ETHSingapore',
    location: 'Singapore, Singapore',
    dates: 'Dec 7-9, 2018',
    avatar: 'ethsg.jpg',
    primaryColor: '#ED4544',
    href: 'https://ethsingapore.co/',
  },
  {
    title: 'ETHSanFrancisco',
    location: 'San Francisco, USA',
    dates: 'Oct 5-7, 2018',
    avatar: 'ethsf.jpg',
    primaryColor: '#F0774A',
    href: 'https://ethsanfrancisco.com/',
  },
  {
    title: 'ETHBerlin',
    location: 'Berlin, Germany',
    dates: 'Sep 7-9, 2018',
    avatar: 'ethberlin.jpg',
    primaryColor: '#262626',
    href: 'https://ethberlin.com/',
  },
  {
    title: 'ETHIndia',
    location: 'Bangalore, India',
    dates: 'Aug 10-12, 2018',
    avatar: 'ethindia.jpg',
    primaryColor: '#203260',
    href: 'https://ethindia.co/',
  },
  {
    title: 'ETHBuenosAires',
    location: 'Buenos Aires, Argentina',
    dates: 'May 25-27, 2018',
    avatar: 'ethba.jpg',
    primaryColor: '#6734FF',
    href: 'https://ethbuenosaires.com/',
  },
  {
    title: 'ETHDenver',
    location: 'Denver, Colorado',
    dates: 'Feb 16-18, 2018',
    avatar: 'ethdenver.jpg',
    primaryColor: '#FBD0DA',
    href: 'http://ethdenver.com/',
  },
  {
    title: 'ETHWaterloo',
    location: 'Waterloo, Ontario',
    dates: 'Oct 13-15, 2017',
    avatar: 'ethwaterloo.jpg',
    primaryColor: '#F5F5F5',
    href: 'http://ethwaterloo.com/',
  },
];

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};
export const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
export const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

const Events = props => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "hackathon" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 180, maxHeight: 180) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="events" {...props} background={theme.color.blue.xlight}>
        <ReactTooltip place="top" effect="solid" />
        <Container>
          <HeadingContainer>
            <h3>Events</h3>
            <h2>Upcoming Events</h2>
          </HeadingContainer>
          <HorizontalScroller>
            {UPCOMING_EVENTS.map(({ avatar, ...event }, index) => {
              const avatarImg = data.allFile.edges.find(
                ({ node }) => node.relativePath === avatar
              ).node;
              return (
                <EventCard
                  avatar={avatarImg.childImageSharp.fluid}
                  key={index}
                  {...event}
                />
              );
            })}
          </HorizontalScroller>
          <h3 style={{ marginTop: 64, textAlign: 'center' }}>Past Events</h3>
          <HorizontalScroller>
            {PREVIOUS_EVENTS.map(({ avatar, ...event }, index) => {
              const avatarImg = data.allFile.edges.find(
                ({ node }) => node.relativePath === avatar
              ).node;
              return (
                <EventCard
                  {...event}
                  avatar={avatarImg.childImageSharp.fluid}
                  key={index}
                  inactive
                />
              );
            })}
          </HorizontalScroller>
        </Container>
      </Section>
    )}
  />
);

const HeadingContainer = styled.div`
  text-align: center;
  margin-top: 16px;

  > h3 {
    color: ${props => props.theme.color.blue.regular};
  }

  > h2 {
    margin: 8px 0;
  }
`;

const CardGrid = styled.div`
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  justify-content: center;
  grid-gap: 30px;
  display: ${props => (props.hide ? 'none' : 'initial')};

  ${props =>
    props.scroll &&
    `
    padding: 0 32px;
    padding-bottom: 24px;
    grid-template-columns: ${`repeat(${props.num}, 256px)`};
    overflow-x: scroll;
    justify-content: start;

    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar { 
      display: none;  // Safari and Chrome
    }
  `};

  @media screen and (max-width: 600px) {
    display: ${props => (props.hide ? 'initial' : 'none')};
  }
`;

const OnlineContainer = styled.a`
  display: flex;
  height: 420px;
  width: 1160px;
  margin: 0 auto;
  background: url('https://i.imgur.com/y8CvcU8.png');
  background-size: cover;
  background-position: bottom center;
  border-radius: 5px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export default Events;
