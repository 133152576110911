import React from 'react';
import styled from 'styled-components';

import { Section, Container, Button } from 'components/global';
import ExternalLink from 'common/ExternalLink';

import Maker from 'static/sponsors/maker.svg';
import Celer from 'static/sponsors/celer.svg';
import Brave from 'static/sponsors/brave.svg';
import Augur from 'static/sponsors/augur.svg';
import ChainLink from 'static/sponsors/chainlink.svg';
import Thegraph from 'static/sponsors/thegraph.svg';
import SetP from 'static/sponsors/set.svg';
import Kyber from 'static/sponsors/kyber.svg';
import Quantstamp from 'static/sponsors/quantstamp.svg';
import Coinbase from 'static/sponsors/coinbase.svg';
import Golem from 'static/sponsors/golem.svg';
import Circle from 'static/sponsors/circle.svg';
import Dydx from 'static/sponsors/dydx.svg';
import Omisego from 'static/sponsors/omisego.svg';
import Gitcoin from 'static/sponsors/gitcoin.svg';
import Zilliqa from 'static/sponsors/zilliqa.svg';
import Aragon from 'static/sponsors/aragon.svg';
import Status from 'static/sponsors/status.svg';
import Ledger from 'static/sponsors/ledger.png';
import Compound from 'static/sponsors/compound.svg';
import ThreeBox from 'static/sponsors/3box.svg';
import Aztec from 'static/sponsors/aztec.svg';
import Deversify from 'static/sponsors/deversify.svg';
import Enigma from 'static/sponsors/enigma.svg';
import Fleek from 'static/sponsors/fleek.svg';
import Fortmatic from 'static/sponsors/fortmatic.svg';
import Infura from 'static/sponsors/infura.svg';
import Matic from 'static/sponsors/matic.svg';
import Metamask from 'static/sponsors/metamask.svg';
import Oraclize from 'static/sponsors/oraclize.svg';
import Pepo from 'static/sponsors/pepo.svg';
import Starkware from 'static/sponsors/starkware.svg';
import Torus from 'static/sponsors/torus.svg';
import TrustToken from 'static/sponsors/trusttoken.svg';
import UMA from 'static/sponsors/uma.svg';
import Uniswap from 'static/sponsors/uniswap.svg';
import Unstoppable from 'static/sponsors/unstoppable.svg';
import UNICEF from 'static/sponsors/unicef.svg';
import Portis from 'static/sponsors/portis.png';
import Covalent from 'static/sponsors/covalent.svg';

// title is the name of sponsor
// href the link of their website
// Add logo image to static/sponsors/
// img should be first imported as done above
// Assign value in img object below

const SPONSORS = [
  {
    title: 'MakerDao',
    href: '',
    img: Maker,
  },
  {
    title: 'Celer',
    href: '',
    img: Celer,
  },
  {
    title: 'Brave',
    href: '',
    img: Brave,
  },
  {
    title: 'Aztec',
    href: '',
    img: Aztec,
  },
  {
    title: 'Uniswap',
    href: '',
    img: Uniswap,
  },
  {
    title: 'Compound',
    href: '',
    img: Compound,
  },
  {
    title: 'Deversify',
    href: '',
    img: Deversify,
  },
  {
    title: 'Enigma',
    href: '',
    img: Enigma,
  },
  {
    title: 'Fleek',
    href: '',
    img: Fleek,
  },
  {
    title: 'Fortmatic',
    href: '',
    img: Fortmatic,
  },
  {
    title: 'Infura',
    href: '',
    img: Infura,
  },
  {
    title: 'Matic',
    href: '',
    img: Matic,
  },
  {
    title: 'Metamask',
    href: '',
    img: Metamask,
  },
  {
    title: 'Oraclize',
    href: '',
    img: Oraclize,
  },
  {
    title: 'Pepo',
    href: '',
    img: Pepo,
  },
  {
    title: 'Starkware',
    href: '',
    img: Starkware,
  },
  {
    title: 'Torus',
    href: '',
    img: Torus,
  },

  {
    title: 'Coinbase',
    href: '',
    img: Coinbase,
  },
  {
    title: 'UMA',
    href: '',
    img: UMA,
  },

  {
    title: 'Unstoppable',
    href: '',
    img: Unstoppable,
  },
  {
    title: 'Augur',
    href: '',
    img: Augur,
  },
  {
    title: 'Chainlink',
    href: '',
    img: ChainLink,
  },
  {
    title: 'The Graph',
    href: '',
    img: Thegraph,
  },
  {
    title: 'Set Protocol',
    href: '',
    img: SetP,
  },
  {
    title: '3Box',
    href: '',
    img: ThreeBox,
  },
  {
    title: 'Kyber Network',
    href: '',
    img: Kyber,
  },
  {
    title: 'Quantstamp',
    href: '',
    img: Quantstamp,
  },
  {
    title: 'Portis',
    href: '',
    img: Portis,
  },

  {
    title: 'Golem',
    href: '',
    img: Golem,
  },
  {
    title: 'Circle',
    href: '',
    img: Circle,
  },
  {
    title: 'Dy/Dx',
    href: '',
    img: Dydx,
  },
  {
    title: 'Omisego',
    href: '',
    img: Omisego,
  },
  {
    title: 'Gitcoin',
    href: '',
    img: Gitcoin,
  },
  {
    title: 'Zilliqa',
    href: '',
    img: Zilliqa,
  },
  {
    title: 'Aragon',
    href: '',
    img: Aragon,
  },
  {
    title: 'TrustToken',
    href: '',
    img: TrustToken,
  },
  {
    title: 'Status',
    href: '',
    img: Status,
  },
  {
    title: 'Ledger',
    href: '',
    img: Ledger,
  },

  {
    title: 'UNICEF',
    href: '',
    img: UNICEF,
  },
  {
    title: 'Covalent',
    href: '',
    img: Covalent,
  },
];

const Sponsors = props => (
  <Section id="sponsors" {...props}>
    <Container>
      <h2 style={{ textAlign: 'center' }}>Sponsors</h2>
      <center>
        <p>
          We’ve been fortunate enough to have been supported by or worked with
          the companies listed below at our past events
        </p>
      </center>
      <Grid>
        {SPONSORS.map(({ title, href, img }) =>
          href !== '' ? (
            <ExternalLink href={href} key={title}>
              <img src={img} alt={title + 'Logo'} />
            </ExternalLink>
          ) : (
            <div key={title}>
              <img src={img} alt={title + 'Logo'} />
            </div>
          )
        )}
      </Grid>
      <ButtonWrapper>
        <Button as="a" href="mailto:sponsor@ethglobal.com">
          Become a sponsor
        </Button>
      </ButtonWrapper>
    </Container>
  </Section>
);

const Grid = styled.div`
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-gap: 48px;
  justify-content: center;

  div,
  a {
    display: flex;
    align-items: center;
  }

  img {
    width: 100%;
    max-height: 72px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 72px;
  text-align: center;
  text-transform: uppercase;

  ${Button} {
    &:hover {
      text-decoration: none;
    }
  }
`;

export default Sponsors;
